<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Stack border="b">
					<Section>
						<Heading size="1" uppercase>About</Heading>
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 6@md">
				<Section size="large">
					<Stack direction="column" space="1">
						<Images width="240px" image="theme-ar/me.jpg" border />
						<Paragraph micro>Anna Robbins - Front-End Developer</Paragraph>
					</Stack>
				</Section>
			</GridContainer>
			<GridContainer size="12 6@md">
				<Stack width="100%" border="t l@md">
					<Section>
						<Heading size="2">A front-end developer passionate about creating beautiful user-centered interfaces.</Heading>
						<Heading size="6">Hello! I'm Anna Robbins. I am a remote front-end developer, currently located in sunny Las Vegas, Nevada.</Heading>
						<Paragraph>I take pride in crafting innovative solutions with well thought out user experiences. I thrive on making magical expiriences and bringing ideas to life. All while maintaining my passion for design, accessibility, performance, and well-structured code.</Paragraph>
						<Paragraph>After graduating from community college with an Associates in Multimedia and Interactive Technologies, I started my career at a medium sized insurance company in Abilene, Texas. I worked in the home office for 5 and a half years before summoning up the courage to ask if I could work remotely. I have continued working for them another 5 years from the Pacific Northwest, where I grew up, and now from Las Vegas, Nevada.</Paragraph>
						<Paragraph>I've had the opportunity to create two design systems for the company. From strategy to implementation to maintenance and more, I enjoy taking design system initiatives to the next level. I am a well-organized person, problem solver, independent employee with high attention to detail. I am happiest when I'm creating, learning, exploring and thinking about how I can make things better.</Paragraph>
						<Button url="../../../public/files/resume.pdf" label="RESUME" color="tertiary" newTab />
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12">
				<Stack border="b"></Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 4@md">
				<Stack width="100%">
					<Section>
						<Heading size="2" margin="none" uppercase>Interests</Heading>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md">
				<Stack direction="column" align="left" width="100%" border="t r@sm lr@md">
					<Stack width="100%" border="b">
						<Section>
							<Heading size="5" margin="none" uppercase>Professional</Heading>
						</Section>
					</Stack>
					<Section>
						<Stack direction="column" width="100%" align="left" space="1">
							<Paragraph>HTML, SCSS, JavaScript</Paragraph>
							<Paragraph>Vuejs, React Native</Paragraph>
							<Paragraph>Adobe Suites</Paragraph>
							<Paragraph>Attention to Details</Paragraph>
							<Paragraph>Innovative Problem Solving</Paragraph>
							<Paragraph>Organization</Paragraph>
						</Stack>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md">
				<Stack direction="column" align="left" width="100%">
					<Stack width="100%" border="tb b@sm">
						<Section>
							<Heading size="5" margin="none" uppercase>Personal</Heading>
						</Section>
					</Stack>
					<Section>
						<Stack direction="column" width="100%" align="left" space="1">
							<Paragraph>Traveling</Paragraph>
							<Paragraph>Binge Watching Shows</Paragraph>
							<Paragraph>All Things Disney</Paragraph>
							<Paragraph>Cooking</Paragraph>
							<Paragraph>Trying New Art Mediums</Paragraph>
							<Paragraph>Charcuterie and Wine</Paragraph>
						</Stack>
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/annarobbins/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  }
}
</script>

<style lang="scss">
</style>
